import { Box } from '@material-ui/core';
import { FC } from 'react';

import Collection from '@/components/Collection';
import { useAccountState } from '@/context/accountContext';
import { useRecommendations } from '@/hooks/useRecommendations';

interface RecommendedMoviesCollectionProps {
  clientIp?: string;
}

const RecommendedMoviesCollection: FC<RecommendedMoviesCollectionProps> = ({ clientIp = '' }) => {
  const account = useAccountState();
  const { recommendations } = useRecommendations({ authUser: account.auth, clientIp });

  return recommendations?.length ? (
    <Box mt="12rem">
      <Collection title="Rekommenderat för dig" movies={recommendations} />
    </Box>
  ) : (
    <> </>
  );
};

export default RecommendedMoviesCollection;
