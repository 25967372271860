import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FunctionComponent } from 'react';

import { Typography } from '@/components/Typography';
import { mqMax } from '@/styles/breakpoints';

import { HeroTop } from '../../payload-types';
import { MediaComponent } from '../components/Media';
import { useMediaQuery } from '@material-ui/core';

interface TitleProps {
  fontFamily?: string;
  marginLeft?: string;
  zIndex?: number;
}

interface MediaWrapperProps {
  top?: number | string;
  right?: number | string;
  left?: number | string;
  bottom?: number | string;
}

const Wrapper = styled.div`
  position: relative;
  min-height: 85rem;
  margin: 2rem 8rem;

  ${mqMax[2]} {
    margin: 8rem;
  }

  ${mqMax[3]} {
    margin: 1rem;
    min-height: 80rem;
    margin-bottom: 8rem;
  }

  ${mqMax[4]} {
    margin: 1rem;
    margin-top: 3rem;
    margin-bottom: 8rem;
  }

  ${mqMax[6]} {
    height: 50vw;
    min-height: 50vw;
  }
`;

const TitleContainer = styled.div<TitleProps>`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 'initial')};
  z-index: ${(props) => (props.zIndex ? props.zIndex : 0)};
  word-break: break-word;
  hyphens: auto;

  // ${`@media (max-width: 1300px)`} {
  //   margin-left: 0;
  // }
`;

const MediaWrapper = styled.div<MediaWrapperProps>`
  position: absolute;
  width: 28vw;
  max-width: 430px;

  top: ${(props) => (typeof props.top === 'number' ? `${props.top}px` : typeof props.top === 'string' ? `${props.top}` : 'initial')};
  bottom: ${(props) =>
    typeof props.bottom === 'number' ? `${props.bottom}px` : typeof props.bottom === 'string' ? `${props.bottom}` : 'initial'};

  right: ${(props) =>
    typeof props.right === 'number' ? `${props.right}px` : typeof props.right === 'string' ? `${props.right}` : 'initial'};
  left: ${(props) =>
    typeof props.left === 'number' ? `${props.left}px` : typeof props.left === 'string' ? `${props.left}` : 'initial'};

  // ${mqMax[3]} {
  //   width: 50vw;
  // }

  /* ${mqMax[4]} {
    right: ${(props) => (typeof props.right === 'number' || typeof props.right === 'string' ? `${0}px` : 'initial')};
    left: ${(props) => (typeof props.left === 'number' || typeof props.left === 'string' ? `${0}px` : 'initial')};
  } */
`;

const TitlesWrapper = styled.div`
  position: absolute;
  z-index: 2;
  ${mqMax[4]} {
    margin-top: 4rem;
  }
`;

const HeroTopBlock: FunctionComponent<HeroTop> = ({ firstMedia, secondMedia, id }) => {
  const lt1280 = useMediaQuery(mqMax[6]);
  const lt1440 = useMediaQuery(mqMax[2]);
  const lt1024 = useMediaQuery(mqMax[3]);
  const lt600 = useMediaQuery(mqMax[4]);
  return (
    <Wrapper key={id}>
      <TitlesWrapper>
        <TitleContainer>
          <Typography
            variant="displayMedium"
            fontWeight="500"
            customColor="#EBEBEB"
            styles={css`
              letter-spacing: -3px;
              ${mqMax[3]} {
                font-size: 15vw;
              }
              ${mqMax[4]} {
                font-size: 68px;
              }
              ${mqMax[6]} {
                font-size: 12vw;
              }
            `}
          >
            Kuraterad
          </Typography>
        </TitleContainer>
        <TitleContainer>
          <Typography
            variant="displayMedium"
            fontStyle="italic"
            customColor="#EBEBEB"
            fontWeight="500"
            styles={css`
              line-height: 1.15;
              ${mqMax[3]} {
                font-size: 15vw;
              }
              ${mqMax[4]} {
                font-size: 68px;
              }
              ${mqMax[6]} {
                font-size: 12vw;
              }
            `}
          >
            kvalitetsfilm.
          </Typography>
        </TitleContainer>
        <TitleContainer marginLeft={lt1440 ? "28vw" : "400px" }>
          <Typography
            variant="displayMedium"
            customColor="#EBEBEB"
            fontWeight="500"
            styles={css`
              letter-spacing: -3px;
              ${mqMax[3]} {
                font-size: 15vw;
              }
              ${mqMax[4]} {
                font-size: 68px;
              }
              ${mqMax[6]} {
                font-size: 12vw;
              }
            `}
          >
            Var du vill,
          </Typography>
        </TitleContainer>
        <TitleContainer zIndex={2}>
          <Typography
            variant="displayMedium"
            customColor="#EBEBEB"
            fontWeight="500"
            styles={css`
              letter-spacing: -3px;
              ${mqMax[3]} {
                font-size: 15vw;
              }
              ${mqMax[4]} {
                font-size: 68px;
              }
              ${mqMax[6]} {
                font-size: 12vw;
              }
            `}
          >
            när du vill.
          </Typography>
        </TitleContainer>
      </TitlesWrapper>
      <MediaWrapper right={0} top={lt600 ? 40 : 20}>
        <MediaComponent source={firstMedia} />
      </MediaWrapper>
      <MediaWrapper bottom={lt600 ? "1vw" : lt1024 ? "8vw" : lt1280 ? 110 : 280} left={0}>
        <MediaComponent source={secondMedia} />
      </MediaWrapper>
    </Wrapper>
  );
};

export { HeroTopBlock };
