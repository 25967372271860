import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, useMediaQuery } from '@material-ui/core';
import Link from 'next/link';
import { FC } from 'react';

import { Typography } from '@/components/Typography/Typography';
import { ArticleMovieInfo } from '@/payload-types';
import { mqMax } from '@/styles/breakpoints';

import { ArrowIcon } from '../components/ArrowIcon';
import { MediaComponent } from '../components/Media';

interface ArticleMovieInfoBlockProps {
  block: ArticleMovieInfo;
}

const Container = styled.div<{ colorBlockToLeft?: boolean }>`
  display: flex;
  flex-direction: ${({ colorBlockToLeft }) => (colorBlockToLeft ? 'row-reverse' : 'row')};
  margin: 8rem 0;
  ${mqMax[3]} {
    flex-direction: column;
  }
`;

const ColorColumn = styled.div<{ backgroundColor?: string }>`
  width: 30%;
  background-color: ${({ backgroundColor, theme }) => backgroundColor ?? theme.palette.primary.main};
  ${mqMax[3]} {
    width: 100%;
    min-height: 200px;
  }
`;

const MarginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 8rem;
  height: calc(100% - 16rem);
  ${mqMax[3]} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    max-width: 600px;
    margin: 1rem auto 5rem auto;
    padding: 0 3rem 0 1rem;
    height: auto;
  }

  // ${mqMax[4]} {
  //   padding: 2rem;
  // }
`;


const MediaColumn = styled.div`
  width: 70%;
  
  ${mqMax[3]} {
    width: 100%;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  ${mqMax[3]} {
    justify-content: center;
    align-items: baseline;
    transform: translateY(100%);
  }
`;

const TextContainer = styled.div`
  margin-top: 3rem;

  ${mqMax[3]} {
    margin-top: 0;
    max-width: 400px;
    padding-left: 2rem;
  }

  ${mqMax[4]} {
    padding-left: 1rem;
    padding-right: 2rem;
  }
`;

const StyledLink = styled(Link)<{ justifyContent?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
`;

const ArticleMovieInfoBlock: FC<ArticleMovieInfoBlockProps> = ({ block }) => {
  const isMobile = useMediaQuery(mqMax[3]);
  return (
    <Container colorBlockToLeft={block.colorBlockToLeft as boolean}>
      <MediaColumn>
        <MediaComponent source={block.MovieInfoMedia} />
      </MediaColumn>
      <ColorColumn backgroundColor={block.backgroundColor?.color as string}>
        <MarginContainer>
          <ArrowContainer>
            {
            block.movieInfo?.movieInfoText ? (block?.linkUrl ? (
            <StyledLink href={block?.linkUrl || ''}>
              <ArrowIcon
                color={block.arrowColor?.color as string}
                size={64}
                direction={isMobile ? 'top' : block.colorBlockToLeft ? 'right' : 'left'}
              />
            </StyledLink>
            ) : (
              <ArrowIcon
                color={block.arrowColor?.color as string}
                hoverColor={block.arrowColor?.color as string}
                size={64}
                direction={isMobile ? 'top' : block.colorBlockToLeft ? 'right' : 'left'}
              />
            )) : (<></>)
            }
          </ArrowContainer>
          <TextContainer>
            <Typography
              variant="bodySmall"
              customColor={block.movieInfo?.color as string}
              styles={css`
                font-size: 14px;
              `}
            >
              {block.movieInfo?.movieInfoText}
            </Typography>
          </TextContainer>
        </MarginContainer>
      </ColorColumn>
    </Container>
  );
};

export default ArticleMovieInfoBlock;
