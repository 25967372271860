import { Box } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { FC, useCallback, useEffect, useState } from 'react';

import { useAccountState } from '@/context/accountContext';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';
import { useSubscriptionData } from '@/context/subscription/subscription.provider';
import { ILandingPage, IOfferIds } from '@/models/ILandingPage.interface';
import { startaPrenumerationClick } from '@/utils/analytics';

import { Button } from './Button/Button';
import { Typography } from './Typography';

import getMeta from '../utils/getMeta';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(12, 4),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    maxWidth: theme.spacing(120),
  },
  disclaimer: {
    display: 'block',
    fontSize: 14,
    marginTop: theme.spacing(2),
  },
}));

interface SubscriptionBlockProps {
  offerIds?: IOfferIds[];
}

const SubscriptionBlock: FC<SubscriptionBlockProps> = ({ offerIds }) => {
  const classes = useStyles();
  const dialogDispatch = useDialogDispatch();
  const account = useAccountState();

  const [offers, setOffers] = useState<IOfferIds[]>([]);

  const { isActiveSubscriber } = useSubscriptionData();

  const handleSubscribe = () => {
    startaPrenumerationClick('footer', getMeta('pageType'));
    dialogDispatch({
      type: dialogActionTypes.DIALOG_AUTH,
      payload: { offerIds: offers, canBuyHavingOffer: true },
    });
  };

  const fetchOfferIds = useCallback(async () => {
    const { data: landingpage } = await axios.get<ILandingPage>(`${process.env.NEXT_PUBLIC_CMS_URL}/landingpage`);
    setOffers([...(offerIds ?? []), ...(landingpage?.offerIds || [])]);
  }, [offerIds]);

  useEffect(() => {
    fetchOfferIds();
  }, [fetchOfferIds]);

  return isActiveSubscriber || !account?.auth.token ? null : (
    <Box mt={8} mb={8}>
      <Box className={classes.content}>
        <Box className={classes.title}>
          <Typography variant="titleMedium">Handplockad film. Prova gratis i 2 veckor.</Typography>
        </Box>
        <Button color="red" size="small" href="/offers" onClick={handleSubscribe} openInNewTab={false}>
          Prova nu
        </Button>
      </Box>
    </Box>
  );
};

export default SubscriptionBlock;
