import styled from '@emotion/styled';
import { FunctionComponent } from 'react';

import LinkButton from '@/components/Button/LinkButton';
import { Typography } from '@/components/Typography';
import { mqMax } from '@/styles/breakpoints';

import Iphone13 from '../../assets/icons/iphone-13-mockup_transparent.png';
import AndroidTV from '../../assets/icons/logotype_android-tv_black.svg';
import Android from '../../assets/icons/logotype_android_black.svg';
import IphoneLogo from '../../assets/icons/logotype_apple-iphone_black.svg';
import AppleTV from '../../assets/icons/logotype_apple-tv_black.svg';
import SamsungTV from '../../assets/icons/logotype_samsung-smart-tv_black.svg';
import { PhoneHero } from '../../payload-types';
import { MediaComponent } from '../components/Media';

interface WrapperProps {
  backgroundColor?: string;
}

interface ParagraphProps {
  color?: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  min-height: 100rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'initial')};

  padding: 8rem 8rem 8rem;
  overflow: hidden;
  ${mqMax[3]} {
    padding: 4rem;
    padding-bottom: 8rem;
    min-height: 70rem;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  max-width: 800px;

  ${mqMax[6]} {
    width: 70%;
    max-width: 600px;
  }

  ${mqMax[3]} {
    width: 70%;
    max-width: 450px;
  }

  ${mqMax[4]} {
    width: 100%;
    max-width: initial;
  }

`;

const Paragraph = styled.p<ParagraphProps>`
  color: ${(props) => (props.color ? props.color : 'black')};
  font-family: 'SunsetGothicPro';
  font-size: 24px;

  width: 60%;
  ${mqMax[4]} {
    width: 100%;
    font-size: 18px;
  }
`;

const PhoneWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ScreenEffectWrapper = styled.div`
  position: absolute;
  right: -200px;
  top: 50%;
  transform: translate(0%, -50%);
  height: 450px;
  width: 873px;
  overflow: hidden;

  ${mqMax[2]} {
    right: -300px;
  }

  ${mqMax[3]} {
    right: -150px;
    top: 50%;
    height: 300px;
    width: 582px;
  }

  ${mqMax[5]} {
    right: -300px;
  }

  ${mqMax[4]} {
    top: 55%;
    right: auto;
    left: 10%;
  }

`;

const PhoneFrame = styled.img`
  height: 100%;
  position: absolute;

  width: 100%;
  z-index: 2;
`;

const MediaContainer = styled.div`
  height: 88%;
  width: 100%;
  position: absolute;
  top: 10px;
  right: -10px;
  z-index: 1;
  border-radius: 70px 0 0 70px;
  overflow: hidden;
  ${mqMax[3]} {
    border-radius: 45px 0 0 45px;
  }
  ${mqMax[4]} {
    border-radius: 30px 0 0 30px;
  }
`;

const LogosWrapper = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin-top: 6rem;

  ${mqMax[4]} {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Logo = styled.img`
  height: 16px;
  margin-right: 16px;
  ${mqMax[4]} {
    margin: 16px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  ${mqMax[4]} {
    flex-direction: column-reverse;
    margin: 32rem auto 0 auto;
  }
`;

export const PhoneHeroBlock: FunctionComponent<PhoneHero> = (props) => {
  return (
    <Wrapper backgroundColor={props.backgroundColor?.color as string} key={props.id}>
      <TextWrapper>
        <Typography variant="displaySmall" fontWeight="600" customColor={props.title.color as string}>
          {props.title.titleText}
        </Typography>
        <Paragraph color={props.paragraph?.color as string}>{props.paragraph?.paragraphText}</Paragraph>
        <LinkButton path={props.link?.linkUrl as string} color={props.link?.color as string} arrowPlacement="right" fontWeight="400" style={{
          paddingLeft: 0
        }}>
          {props?.link?.linkText}
        </LinkButton>
      </TextWrapper>
      <ScreenEffectWrapper>
        <PhoneWrapper>
          <PhoneFrame src={Iphone13.src} />
          <MediaContainer>
            <MediaComponent source={props.screenMedia} />
          </MediaContainer>
        </PhoneWrapper>
      </ScreenEffectWrapper>
      <FlexWrapper>
        <LogosWrapper>
          <Logo src={Android.src} alt="" /> <Logo src={AndroidTV.src} alt="" /> <Logo src={IphoneLogo.src} alt="" />
          <Logo src={AppleTV.src} alt="" /> <Logo src={SamsungTV.src} alt="" />
        </LogosWrapper>
      </FlexWrapper>
    </Wrapper>
  );
};
