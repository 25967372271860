import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';

import { Typography } from '@/components/Typography/Typography';
import { ArticleQuote } from '@/payload-types';
import { mqMax } from '@/styles/breakpoints';

interface ArticleQuoteBlockProps {
  block: ArticleQuote;
}

const ArticleQuoteContainer = styled.div<{ borderColor?: string }>`
  border-left: ${({ borderColor, theme }) => ` 8px solid ${borderColor ?? theme.palette.primary.main};`};
  padding: 4rem;
  max-width: 900px;
  margin: 8rem auto;
  ${mqMax[3]} {
    max-width: 720px;
    padding: 2rem;
  }

  ${mqMax[5]} {
    margin: 2rem;
    padding: 2rem;
  }
`;

const ArticleQuoteBlock: FC<ArticleQuoteBlockProps> = ({ block }) => {
  return (
    <ArticleQuoteContainer borderColor={block.lineColor as string}>
      <Typography
        variant="titleLarge"
        fontStyle="italic"
        fontWeight="600"
        customColor={block.quotation?.quotationColor ?? '#121212'}
        styles={css`
          line-height: 130%;
          font-size: 46px;
          ${mqMax[2]} {
            font-size: 46px;
          }
          ${mqMax[3]} {
            font-size: 42px;
          }
          ${mqMax[5]} {
            font-size: 42px;
          }
          ${mqMax[4]} {
            font-size: 36px;
          }
        `}
      >
        ”{block.quotation?.quotationText}”
      </Typography>
    </ArticleQuoteContainer>
  );
};

export default ArticleQuoteBlock;
