import Link from 'next/link';
import { FC } from 'react';

import { ButtonProps, IconButtonWrapper } from './utils';

const IconButton: FC<ButtonProps> = ({
  children,
  href = '',
  onClick,
  size = 'small',
  color = 'black',
  dataTestId,
  disabled = false,
  hoverBackgroundColor,
  hoverFontColor,
}) => {
  return (
    <div>
      {onClick ? (
        <IconButtonWrapper
          size={size}
          onClick={onClick}
          color={color}
          data-testid={dataTestId}
          disabled={disabled}
          hoverBackgroundColor={hoverBackgroundColor}
          hoverFontColor={hoverFontColor}
        >
          {children}
        </IconButtonWrapper>
      ) : (
        <Link href={href} target="_blank">
          <IconButtonWrapper
            size={size}
            color={color}
            data-testid={dataTestId}
            disabled={disabled}
            hoverBackgroundColor={hoverBackgroundColor}
            hoverFontColor={hoverFontColor}
          >
            {children}
          </IconButtonWrapper>
        </Link>
      )}
    </div>
  );
};

export { IconButton };
