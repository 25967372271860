import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';

import { DarkOverlay } from '@/components/DarkOverlay/DarkOverlay';
import { Typography } from '@/components/Typography';
import { PageHeadline } from '@/payload-types';
import { mqMax } from '@/styles/breakpoints';

import { MediaComponent } from '../components/Media';

const ContactHeadlineTextContainer = styled.div`
  position: absolute;
  padding: 8rem 12rem 8rem;
  height: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 1;
  ${mqMax[3]} {
    padding: 5rem;
    max-width: 600px;
  }

  ${mqMax[4]} {
    padding: 5rem 2rem 5rem 2rem;
    max-width: 470px;
  }
`;

const ContactHeadlineContainer = styled.div`
  position: relative;
  height: 700px;

  ${mqMax[3]} {
    height: 600px;
  }

  ${mqMax[4]} {
    height: 400px;
  }
`;

const MediaContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

interface PageHeadlineBlockProps {
  block: PageHeadline;
}

const PageHeadlineBlock: FC<PageHeadlineBlockProps> = (props) => {
  const { headline, preamble, headlineMedia, id } = props.block;

  return (
    <ContactHeadlineContainer key={id}>
      <ContactHeadlineTextContainer>
        <Typography
          variant="headlineSmall"
          customColor={headline?.color as string}
          fontWeight="500"
          styles={css`
            margin-bottom: 20px;
          `}
        >
          {headline?.headlineText}
        </Typography>
        <Typography variant="bodyLarge" customColor={preamble?.color as string} fontWeight='300'>
          {preamble?.preambleText}
        </Typography>
      </ContactHeadlineTextContainer>
      {headlineMedia && (
        <MediaContainer>
          <MediaComponent source={headlineMedia} />
          <DarkOverlay />
        </MediaContainer>
      )}
    </ContactHeadlineContainer>
  );
};

export default PageHeadlineBlock;
