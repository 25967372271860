import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { FC, useMemo, useRef, useState } from 'react';
import { useHoverDirty } from 'react-use';

import { IconButton } from '../Button/IconButton';
import { Typography } from '../Typography';
import CheckmarkIconBlack from '../icons/new/CheckmarkBlack';
import MinusIconBlack from '../icons/new/MinusIconBlack';
import PlusIconBlack from '../icons/new/PlusIconBlack';

interface LikeMovieButtonProps {
  inMyList: boolean;
  handleRemoveFromFavorites: () => void;
  handleAddToFavorites: () => void;
  timeout?: number;
  size?: 'large' | 'small';
}

const LikeMovieButton: FC<LikeMovieButtonProps> = ({
  inMyList,
  handleRemoveFromFavorites,
  handleAddToFavorites,
  timeout = 1000,
  size = 'small',
}) => {
  const [removedInfoMessage, setRemovedInfoMessage] = useState('');
  const [addedInfoMessage, setAddedInfoMessage] = useState('');

  const ref = useRef(null);
  const hovered = useHoverDirty(ref);

  const removeFromFavorites = () => {
    handleRemoveFromFavorites();
    setRemovedInfoMessage('Borttagen från min lista');
    setTimeout(() => {
      setRemovedInfoMessage('');
    }, timeout);
  };

  const addToFavorites = () => {
    handleAddToFavorites();
    setAddedInfoMessage('Tillagd i min lista');
    setTimeout(() => {
      setAddedInfoMessage('');
    }, timeout);
  };

  const infoMessage = useMemo(
    () =>
      removedInfoMessage ||
      addedInfoMessage ||
      (hovered ? (inMyList ? 'Ta bort från min lista' : 'Lägg till i min lista') : ''),
    [removedInfoMessage, addedInfoMessage, inMyList, hovered]
  );

  const icon = addedInfoMessage ? <CheckmarkIconBlack /> : inMyList ? <MinusIconBlack /> : <PlusIconBlack />;

  return (
    <div ref={ref}>
      <Box display="flex" alignItems="center">
        <IconButton
          color="white"
          hoverBackgroundColor="#EC6446"
          hoverFontColor="#FFFFFF"
          onClick={inMyList ? removeFromFavorites : addToFavorites}
          size={size}
        >
          {icon}
        </IconButton>
        <Box display="flex" alignItems="center">
          <Typography
            variant="labelSmall"
            styles={css`
              margin-left: 1rem;
            `}
          >
            {infoMessage}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default LikeMovieButton;
