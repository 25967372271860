import { Box } from '@material-ui/core';
import axios from 'axios';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import Collection from '@/components/Collection';
import { GetMagineIdBasicMovieQuery } from '@/graphql/queries/__generated__/movie.generated';
import { createVariablesObject, createViewables, getMoviesGraphQLQueryArguments } from '@/hooks/utils';
import { IAuthUser } from '@/models/IAuthUser.interface';
import { RelatedMovies } from '@/payload-types';
import styled from '@emotion/styled';

const relatedMoviesData: Map<
  string,
  {
    timestamp: Date;
    recommendations: Array<GetMagineIdBasicMovieQuery['viewer']['viewable'] & { __typename: 'Movie' }>;
  }
> = new Map();

const RelatedMoviesContainer = styled.div`
  background-color: #0f0f0f;
  padding-top: 0.1rem;
  padding-bottom: 3rem;
  position: relative;
  z-index: 10;
`;

const RelatedMoviesBlock: FC<{ block: RelatedMovies; clientIp?: string; authUser?: IAuthUser }> = (props) => {
  const [relatedMovies, setRelatedMovies] = useState<
    Array<GetMagineIdBasicMovieQuery['viewer']['viewable'] & { __typename: 'Movie' }>
  >([]);

  const { block, clientIp, authUser } = props;

  const moviesIds = useMemo(() => block.movieIds?.map((movie) => movie?.movieMagineId || '') ?? [], [block]);

  const getMovies = useCallback(async () => {
    if (moviesIds.length > 0) {
      await axios({
        url: process.env.NEXT_PUBLIC_MAGINE_API_QGL_V2,
        data: {
          variables: createVariablesObject(moviesIds),
          context: { authToken: authUser?.token, clientIp },
          query: `
            query GetMagineMovies(${getMoviesGraphQLQueryArguments(moviesIds)}) {
              viewer {
                ${createViewables(moviesIds)}
              }
            }
          `,
        },
        method: 'post',
        headers: authUser?.token
          ? {
              'Magine-AccessToken': process.env.NEXT_PUBLIC_MAGINE_API_TOKEN,
              Authorization: `Bearer ${authUser.token}`,
              'X-Forwarded-For': clientIp,
            }
          : {
              'Magine-AccessToken': process.env.NEXT_PUBLIC_MAGINE_API_TOKEN,
              'X-Forwarded-For': clientIp,
            },
      })
        .then((response) => {
          if (response?.data?.data?.viewer) {
            const mappedRelatedMovies = Object.values(response?.data?.data?.viewer).filter((movie) => movie);
            relatedMoviesData.set(authUser?.userId ?? '', {
              timestamp: new Date(),
              recommendations: mappedRelatedMovies as Array<
                GetMagineIdBasicMovieQuery['viewer']['viewable'] & { __typename: 'Movie' }
              >,
            });
            setRelatedMovies(
              mappedRelatedMovies as Array<GetMagineIdBasicMovieQuery['viewer']['viewable'] & { __typename: 'Movie' }>
            );
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  }, [authUser?.token, authUser?.userId, clientIp, moviesIds]);

  useEffect(() => {
    getMovies();
  }, [getMovies]);

  return relatedMovies?.length ? (
    <RelatedMoviesContainer>
      <Collection title={block.title ?? 'Related:'} movies={relatedMovies} />
    </RelatedMoviesContainer>
  ) : (
    <> </>
  );
};

export default RelatedMoviesBlock;
