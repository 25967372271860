import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import { FunctionComponent, useRef, useState } from 'react';
import Slider from 'react-slick';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography';
import { mqMax } from '@/styles/breakpoints';

import { SlideshowHero } from '../../payload-types';
import { ArrowIcon } from '../components/ArrowIcon';
import { MediaComponent } from '../components/Media';

import { useInterval } from 'react-use';

const Wrapper = styled.div<{ backgroundColor?: string }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10rem 0;

  background-color: ${({ backgroundColor, theme }) => backgroundColor ?? theme.palette.background.default};
  ${mqMax[4]} {
    height: 100%;
  }
  .slick-slide img {
    aspect-ratio: 16/9;
  }
`;

const HeadlineWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const BodyWrapper = styled.div`
  bottom: 4rem;
  width: 30rem;
  margin-top: 4rem;
`;

const FadeAndHeadline = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CSSFadeWrapper = styled.div`
  position: relative;
  width: 80%;
  aspect-ratio: 16/9;
  overflow: hidden;
`;

const CSSFadeElement = styled.div<{ }>`
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s;
  z-index: 1;
`;

export const SlideshowHeroBlock: FunctionComponent<SlideshowHero> = (props) => {
  const { id, backgroundMedia, backgroundColor, headline, body, buttonType, buttonLinkText, buttonLinkSlug } = props;

  const [activeElement, setActiveElement] = useState(0);

  const handleCSSFade = () => {
    if (activeElement < ( backgroundMedia?.length || 0 ) - 1 ) {
      setActiveElement(activeElement + 1);
    } else {
      setActiveElement(0);
    }
  };

  useInterval(() => {
    handleCSSFade();
  }, 5000);


  return (
    <Wrapper key={id} backgroundColor={backgroundColor as string}>
      <FadeAndHeadline>
        <CSSFadeWrapper>
        {
          backgroundMedia?.map((media, index) => {
            return (
              <CSSFadeElement key={media.id} style={{
                opacity: activeElement === index ? 1 : 0
              }}>
                <MediaComponent source={media.backgroundMedia} />
              </CSSFadeElement>
            );
          })
        }
        </CSSFadeWrapper>
        <HeadlineWrapper>
          <Typography
            variant="displayLargeDynamic"
            fontWeight="600"
            fontStyle="italic"
            customColor={headline?.color as string}
            styles={css`
              opacity: 0.85;
              text-align: center;
              width: 100%;
            `}
          >
            {headline?.headlineText}
          </Typography>
        </HeadlineWrapper>
      </FadeAndHeadline>
      <BodyWrapper>
        <Typography variant="bodyMedium" align="center" customColor={body?.color as string}>
          {body?.bodyText}
        </Typography>
        {buttonType === 'button' && buttonLinkSlug && (
          <Box display="flex" justifyContent="center" mt={4}>
            <Button color="black" size="large" href={buttonLinkSlug} multiply={true} openInNewTab={false}>
              {buttonLinkText}
            </Button>
          </Box>
        )}
        {buttonType === 'arrow' && (
          <Box display="flex" justifyContent="center" mt={6}>
            <ArrowIcon color={body?.color as string} direction="top" size={52} />
          </Box>
        )}
      </BodyWrapper>
    </Wrapper>
  );
};
