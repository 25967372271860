import { FC } from 'react';

import { IAuthUser } from '@/models/IAuthUser.interface';

import ArticleMovieInfoBlock from './blocks/ArticleMovieInfoBlock';
import ArticleQuoteBlock from './blocks/ArticleQuoteBlock';
import { ArticlesCaruselBlock } from './blocks/ArticlesCaruselBlock';
import { CaruselBlock } from './blocks/CaruselBlock';
import { CinemaHeroBlock } from './blocks/CinemaHeroBlock';
import ContactDetailsBlock from './blocks/ContactDetailsBlock';
import { DrakenFilmHeroBlock } from './blocks/DrakenFilmHeroBlock';
import { HeroTopBlock } from './blocks/HeroTopBlock';
import { MoviesCaruselBlock } from './blocks/MoviesCaruselBlock';
import MoviesCollectionsBlock from './blocks/MoviesCollectionsBlock';
import PageHeadlineBlock from './blocks/PageHeadlineBlock';
import { PhoneHeroBlock } from './blocks/PhoneHeroBlock';
import RecommendedMoviesCollection from './blocks/RecommendedMoviesCollection';
import RelatedMoviesBlock from './blocks/RelatedMoviesBlock';
import { RichtextBlock } from './blocks/RichtextBlock';
import { SlideshowHeroBlock } from './blocks/SlideshowHeroBlock';
import SubscriptionOffersBlock from './blocks/SubscriptionOffersBlock';

import {
  ArticleMovieInfo,
  ArticleQuote,
  ArticleRichtext,
  ArticlesCarusel,
  Carusel,
  CinemaHero,
  ContactDetails,
  DrakenFilmHero,
  HeroTop,
  MoviesCarusel,
  MoviesCollections,
  Page,
  PageHeadline,
  PhoneHero,
  RecommendedMoviesCollection as RecommendedMoviesCollectionType,
  RelatedMovies,
  SlideshowHero,
  SubscriptionOffers,
} from '../payload-types';

interface RenderBlocksProps {
  blocks: Array<
    | HeroTop
    | CinemaHero
    | PhoneHero
    | ArticlesCarusel
    | MoviesCarusel
    | Carusel
    | DrakenFilmHero
    | SlideshowHero
    | ArticleMovieInfo
    | ArticleRichtext
    | ArticleQuote
    | PageHeadline
    | MoviesCollections
    | ContactDetails
    | SubscriptionOffers
    | RecommendedMoviesCollectionType
    | RelatedMovies
  >;
  clientIp?: string;
  authUser?: IAuthUser;
  isArticle?: boolean;
  includeIds?: Page['accessOfferIds'];
  hasSubscriptionForThisPage?: boolean;
}

const RenderBlocks: FC<RenderBlocksProps> = ({
  blocks,
  clientIp,
  authUser,
  isArticle,
  includeIds,
  hasSubscriptionForThisPage,
}) => {
  return (
    <>
      {blocks?.map((block, index) => {
        switch (block.blockType) {
          case 'HeroTop':
            return <HeroTopBlock key={block.id} {...block} />;
          case 'CinemaHero':
            return <CinemaHeroBlock key={block.id} {...block} />;
          case 'PhoneHero':
            return <PhoneHeroBlock key={block.id} {...block} />;
          case 'ArticlesCarusel':
            return <ArticlesCaruselBlock key={block.id} {...block} />;
          case 'MoviesCarusel':
            return <MoviesCaruselBlock key={block.id} authUser={authUser} clientIp={clientIp} {...block} />;
          case 'Carusel':
            return <CaruselBlock key={block.id} {...block} />;
          case 'DrakenFilmHero':
            return <DrakenFilmHeroBlock key={block.id} {...block} />;
          case 'SlideshowHero':
            return <SlideshowHeroBlock key={block.id} {...block} />;
          case 'ArticleMovieInfo':
            return <ArticleMovieInfoBlock block={block} />;
          case 'ArticleRichtext': {
            const firstIndex = blocks.findIndex((b) => b.blockType === 'ArticleRichtext');
            const lastIndex = blocks.map((b) => b.blockType).lastIndexOf('ArticleRichtext');

            return (
              <RichtextBlock
                block={block}
                isFirst={index === firstIndex}
                isLast={index === lastIndex}
                isArticle={isArticle}
              />
            );
          }
          case 'ArticleQuote':
            return <ArticleQuoteBlock block={block} key={index} />;
          case 'PageHeadline':
            return <PageHeadlineBlock block={block} key={index} />;
          case 'MoviesCollections':
            return (
              <MoviesCollectionsBlock
                block={block}
                clientIp={clientIp}
                hasSubscriptionForThisPage={hasSubscriptionForThisPage}
                key={index}
              />
            );
          case 'ContactDetails':
            return <ContactDetailsBlock block={block} key={index} />;
          case 'SubscriptionOffers':
            return (
              <SubscriptionOffersBlock
                block={block}
                includeOfferIds={includeIds}
                hasSubscriptionForThisPage={hasSubscriptionForThisPage}
                key={index}
              />
            );
          case 'RecommendedMoviesCollection':
            return <RecommendedMoviesCollection clientIp={clientIp} key={index} />;
          case 'RelatedMovies':
            return <RelatedMoviesBlock clientIp={clientIp} authUser={authUser} block={block} key={index} />;
          default:
            return <></>;
        }
      })}
    </>
  );
};

export { RenderBlocks };
