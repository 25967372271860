import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import { FC } from 'react';

import LinkButton from '@/components/Button/LinkButton';
import SubscriptionBlock from '@/components/SubscriptionBlock';
import { Typography } from '@/components/Typography';
import { ContactDetails } from '@/payload-types';
import { RichtextBlock } from './RichtextBlock';

const ContentContainer = styled.div`
  max-width: 620px;
  margin: 8rem auto;
`;

const ContactItemContainer = styled.div`
  margin-top: 4rem;
`;

interface ContactDetailsBlockProps {
  block: ContactDetails;
}

const ContactDetailsBlock: FC<ContactDetailsBlockProps> = (props) => {
  const { email, phone, address } = props.block;
  const theme = useTheme();
  return (
    <div>
      <ContentContainer>
        <Typography variant="bodyLarge">Våra kontaktuppgifter</Typography>
        <Typography variant="bodySmall">Här finns våra kontaktuppgifter.</Typography>
        <ContactItemContainer>
          <Typography variant="bodySmall" customColor={theme.palette.primary.main}>
            E-post
          </Typography>
          <Typography variant="bodyMedium">{email}</Typography>
        </ContactItemContainer>
        <ContactItemContainer>
          <Typography variant="bodySmall" customColor={theme.palette.primary.main}>
            TelefonnummerXXX
          </Typography>
          <Typography variant="bodyMedium">
            {phone?.map(
              (phoneBlock) =>
                <RichtextBlock key={phoneBlock.id} block={phoneBlock} noMargins={true} />
            )}
          </Typography>
        </ContactItemContainer>
        <ContactItemContainer>
          <Typography variant="bodySmall" customColor={theme.palette.primary.main}>
            Postadress
          </Typography>
          <Typography variant="bodyMedium">
            {address?.map(
              (addressBlock) =>
                <RichtextBlock key={addressBlock.id} block={addressBlock} noMargins={true} />
            )}
          </Typography>
        </ContactItemContainer>
        <Box mt={12}>
          <Typography variant="bodyLarge">Vanliga frågor</Typography>
          <Typography variant="bodySmall">
            Klicka på länken nedan för att se våra vanligaste frågor. Om du inte finner det du söker kontakta oss gärna.
            Våra kontaktuppgifter hittar du ovan.
          </Typography>
        </Box>
        <Box mt={6}>
          <LinkButton path="/villkor" arrowPlacement="right">
            Till vanliga frågor och svar
          </LinkButton>
        </Box>
      </ContentContainer>
      <SubscriptionBlock />
    </div>
  );
};

export default ContactDetailsBlock;
