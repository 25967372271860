import styled from '@emotion/styled';
import { FunctionComponent } from 'react';

import LandingPageArrow from '@/assets/icons/arrows/DF-arrow-V-light.svg';
import { Typography } from '@/components/Typography';
import { mqMax } from '@/styles/breakpoints';

import { CinemaHero } from '../../payload-types';
import { ArrowIcon } from '../components/ArrowIcon';
import { MediaComponent } from '../components/Media';
import { scrollToNext } from '@/utils/scrollToNext';
import { css } from '@emotion/react';

interface SubTitleProps {
  color?: string;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;

const MediaWrapper = styled.div`
  height: 100%;
`;

const SubtTitle = styled.p<SubTitleProps>`
  font-size: 24px;
  font-family: 'SunsetGothicPro';
  margin: 0 auto;
  width: 80%;
  padding-top: 4rem;
  color: ${(props) => (props.color ? props.color : 'initial')};

  ${mqMax[3]} {
    font-size: 20px;
  }
  ${mqMax[4]} {
    font-size: 16px;
    padding-top: 2rem;
  }
`;

const TitlesWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  text-align: center;
  hyphens: auto;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const CinemaHeroBlock: FunctionComponent<CinemaHero> = (props) => {
  return (
    <Wrapper key={props.id} className='cinema-hero-block'>
      <MediaWrapper>
        <MediaComponent source={props.backgroundMedia} />
      </MediaWrapper>
      <TitlesWrapper>
        <Typography variant="displayLargeDynamic" fontWeight="600" align='center' customColor={props.title.color as string} styles={css`
          letter-spacing: -0.3vw;
          font-size: 10vw;
          ${mqMax[4]} {
            font-size: 12vw;
          }
        `}>
          {props.title.titleText}
        </Typography>
        <SubtTitle color={props.subTitle.color as string}>{props.subTitle.subTitleText}</SubtTitle>
      </TitlesWrapper>
      <IconWrapper>
        <a onClick={scrollToNext('cinema-hero-block')} style={{
          cursor: 'pointer'
        }}>
          <ArrowIcon color={props.arrowColor?.color as string} arrowSource={LandingPageArrow.src} direction='bottom' />
        </a>
      </IconWrapper>
    </Wrapper>
  );
};
