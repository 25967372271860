import styled from '@emotion/styled';
import { Box, useTheme } from '@material-ui/core';
import { FC } from 'react';
import slugify from 'slugify';
import { css } from '@emotion/react';

import LinkButton from '@/components/Button/LinkButton';
import { Typography } from '@/components/Typography/Typography';
import { Article, Media } from '@/payload-types';
import { mqMax } from '@/styles/breakpoints';

import { MediaComponent } from '../components/Media';

interface ArticlesCaruselItemProps {
  article: Article;
  preambleColor?: string;
  headlineColor?: string;
}

const ArticleCaruselItemWrapper = styled.div`
  display: block;
  height: 100%;
  padding: 6rem 5rem;

  ${mqMax[5]} {
    flex-direction: column-reverse;
    padding: 4rem;
  }
`;

const TextRow = styled.div`
  width: 100%;
  min-height: 290px;

  ${mqMax[5]} {
    min-height: initial;
  }
`;

const TextColumn = styled.div`
  width: 60%;
  padding: 2rem 4rem 2rem 0;

  ${mqMax[3]} {
    width: 70%;
  }

  ${mqMax[5]} {
    width: 100%;
    padding: 0;
  }
`;

const TextColumnBody = styled.div`
  width: 60%;

  ${mqMax[5]} {
    width: 80%;
    margin: 0;
  }

  ${mqMax[4]} {
    width: 100%;
    margin: 0;
  }
`;

const ImageRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${mqMax[5]} {
    margin-top: 2rem;
  }
`;

const ImageColumnLeft = styled.div`
  width: 40%;
  margin: 0rem 4rem 0 0;

  ${mqMax[5]} {
    width: 30%;
    margin: 0;
  }

  ${mqMax[4]} {
    display: none;
  }

`;

const ImageColumn = styled.div`
  width: 60%;
  margin: 2rem 0;
  border: 2px solid #000000;
  margin-top: -10rem;

  ${mqMax[5]} {
    width: 70%;
    margin: 0;
  }

  ${mqMax[4]} {
    width: 100%;
  }

`;

const MobileLinkButton = styled.div`
  display: none;
  ${mqMax[4]} {
    display: block;
  }
`;

const HeadlineContainer = styled.div`
  max-width: 850px;
  padding-right: 3rem;
`;

const ArticlesCaruselItem: FC<ArticlesCaruselItemProps> = ({ article, preambleColor, headlineColor }) => {
  const theme = useTheme();
  return (
    <ArticleCaruselItemWrapper>
      <TextRow>
        <TextColumn>
          <HeadlineContainer>
            <Typography variant="headlineSmall" fontWeight="600" customColor={headlineColor}>
              {article.headline}
            </Typography>
          </HeadlineContainer>
          <TextColumnBody>
            <Box mt={4}>
              <Typography variant="bodyMedium" customColor={preambleColor}>
                {article.preamble?.preambleText}
              </Typography>
              <MobileLinkButton>
              <LinkButton
                path={`/artikel/${slugify(article.slug, { lower: true, strict: true })}`}
                color={theme.palette.common.black}
                arrowPlacement="right"
                fontWeight="400"
                style={{
                  paddingLeft: 0,
                  marginTop: '1rem',
                
                }}
              >
                Läs mer
              </LinkButton>
              </MobileLinkButton>
            </Box>
          </TextColumnBody>
        </TextColumn>
      </TextRow>
      <ImageRow>
        <ImageColumnLeft>
            <LinkButton
              path={`/artikel/${slugify(article.slug, { lower: true, strict: true })}`}
              color={theme.palette.common.black}
              arrowPlacement="right"
              fontWeight="400"
              style={{
                margin: 0,
                padding: 0
              }}
            >
              Läs mer
          </LinkButton>
        </ImageColumnLeft>
        <ImageColumn>
          <MediaComponent source={article.articleImage as string | Media | undefined} style={{ aspectRatio: 16 / 10 }} />
        </ImageColumn>
      </ImageRow>
    </ArticleCaruselItemWrapper>
  );
};

export { ArticlesCaruselItem };
