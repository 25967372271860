import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, useMediaQuery } from '@material-ui/core';
import { FunctionComponent, useState } from 'react';
import Slider, { CustomArrowProps } from 'react-slick';

import LinkButton from '@/components/Button/LinkButton';
import { Typography } from '@/components/Typography/Typography';
import { mqMax } from '@/styles/breakpoints';

import { Carusel } from '../../payload-types';
import { ArrowIcon } from '../components/ArrowIcon';
import { MediaComponent } from '../components/Media';

interface ArrowProps {
  color?: string;
}

interface WrapperProps {
  dotsColor?: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 100rem;
  ${mqMax[3]} {
    height: 60rem;
  }
  .slick-dots {
    bottom: 50px;
    text-align: center;
    li {
      margin: 0;
    }
    li button:before {
      border: 1px solid white;
      border-radius: 50%;
      opacity: 1;
      line-height: 10px;
      font-size: 10px;
      height: 10px;
      width: 10px;
      box-sizing: border-box;

      content: '';
    }

    li.slick-active button:before {
      content: '';
      background-color: ${({ dotsColor, theme }) => dotsColor ?? theme.palette.primary.main};
      border: none;
    }

    .custom-slider {
      height: 100%;
    }
  }
`;

const ContentWrapper = styled.h2`
  position: absolute;
  height: 100%;
  width: 60%;
  margin: 0 auto;
  padding: 8rem 6rem 12rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  /* Centering */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${mqMax[3]} {
    padding: 4rem 2rem 2rem 2rem;
    width: 100%;
  }

  ${mqMax[4]} {
    padding: 6rem 2rem 2rem 2rem;
  }
`;

const CaruselWrapper = styled.div`
  position: relative;
  height: 100rem;
  ${mqMax[3]} {
    height: 60rem;
  }
`;

const MediaWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const getArrowStyles = () => css`
  position: absolute;
  top: 50%;
  width: 100px;
  height: 100px;

  transform: translateY(-50%);
  overflow: hidden;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
`;

const HeadlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  margin-top: -2rem;

  ${mqMax[4]} {
    margin-top: -3rem;
  }
`;

export const StyledNextArrow = styled.div<ArrowProps>`
  ${getArrowStyles};
  right: 2rem;
  visibility: ${(props) => (props.onClick ? 'visible' : 'hidden')};
`;

export const StyledPrevArrow = styled.div<ArrowProps>`
  ${getArrowStyles};
  visibility: ${(props) => (props.onClick ? 'visible' : 'hidden')};
  left: 2rem;
`;

function NextArrow(props: CustomArrowProps & ArrowProps) {
  const { onClick, color } = props;

  return (
    <StyledNextArrow onClick={onClick}>
      <ArrowIcon hoverColor="#FFFFFF" color={color} direction="right" size={84} stroke={2} />
    </StyledNextArrow>
  );
}

function PrevArrow(props: CustomArrowProps & ArrowProps) {
  const { onClick, color } = props;

  return (
    <StyledPrevArrow onClick={onClick}>
      <ArrowIcon color={color} hoverColor="#FFFFFF" direction="left" size={84} stroke={2} />
    </StyledPrevArrow>
  );
}

export const CaruselBlock: FunctionComponent<Carusel> = (props) => {
  const isMobile = useMediaQuery(mqMax[4]);
  const [arrowColor, setArrowColor] = useState(props.item?.[0].headline.color as string);

  const updateArrowColor = (oldIdx: number, newIdx: number) => {
    setArrowColor(props.item?.[newIdx].headline.color as string);
  };


  return (
    <Wrapper dotsColor={arrowColor as string} key={props.id}>
      <Slider
        infinite={true}
        dots={true}
        className="custom-slider"
        arrows={!isMobile}
        nextArrow={<NextArrow color={arrowColor as string} />}
        prevArrow={<PrevArrow color={arrowColor as string} />}
        autoplay={true}
        autoplaySpeed={10000}
        pauseOnHover={false}
        speed={1000}
        beforeChange={updateArrowColor}
      >
        {props.item?.map((item) => {
          return (
            <div key={item.id}>
              <CaruselWrapper>
                <MediaWrapper>
                  <MediaComponent source={item.backgroundMedia} />
                </MediaWrapper>
                <ContentWrapper>
                  <Typography variant="titleLarge" fontStyle="italic" fontWeight="200" customColor={item.title.color as string}>
                    {item.title.titleText}
                  </Typography>
                  <HeadlineWrapper>
                    <Typography
                      variant="headlineMedium"
                      align="center"
                      fontStyle="italic"
                      fontWeight="bold"
                      customColor={item.headline.color as string}
                    >
                      {item.headline.titleText}
                    </Typography>
                    <Box mt="40px" mb="40px" textAlign="center" width="70%">
                      <Typography variant="bodyLarge" customColor={item.description.color as string} align="center">
                        {item.description.descriptionText}
                      </Typography>
                    </Box>

                    {item.link.linkText && (
                      <>
                        <LinkButton
                          path={`${(!item.link.slug?.startsWith('/') && !item.link.slug?.startsWith('https:') ? '/' : '') + item.link.slug?.toLowerCase()}`}
                          color={item.link.color as string}
                          arrowPlacement="right"
                          fontWeight="400"
                          target={item.link.slug?.startsWith('https:') ? '_blank' : '_self'}
                        >
                          {item.link.linkText}
                        </LinkButton>
                      </>
                    )}
                  </HeadlineWrapper>
                </ContentWrapper>
              </CaruselWrapper>
            </div>
          );
        })}
      </Slider>
    </Wrapper>
  );
};
