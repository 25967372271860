import styled from '@emotion/styled';
import NextLink from 'next/link';

import { mqMax } from '@/styles/breakpoints';

interface WrapperProps {
  align: 'flex-start' | 'center';
  backgroundColor?: string;
  backgroundImage: boolean;
}

interface CollectionLinkProps {
  color?: string;
  align?: string;
}

interface LinkWrapperProps {
  align?: 'center' | 'left';
}

interface TitleProps {
  color?: string;
  align: 'left' | 'center';
}

interface ImageTitleWrapperProps {
  align: 'left' | 'center';
}

const Wrapper = styled.div<WrapperProps>`
  padding: 0;
  position: relative;
  height: 100%;
  padding-bottom: 6rem;
  background-color: ${(props) => props.backgroundColor ?? 'transparent'};
  flex-direction: column;

  ${mqMax[6]} {
    padding-bottom: 4rem;
  }
  ${mqMax[4]} {
    padding-bottom: 2rem;
  }
`;

const MediaWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #000000;
`;

const DefaultTitle = styled.div<TitleProps>`
  font-size: 25px;
  color: ${({ color, theme }) => color ?? theme.palette.text.primary};
  font-family: 'SunsetGothicProItalic';
`;

const TextWrapper = styled.div`
  z-index: 1;
  margin-top: 4rem;
  padding: 4rem 10rem 4rem 10rem;
  ${mqMax[3]} {
    padding: 2rem 10rem 2rem 10rem;
  }
  ${mqMax[5]} {
    padding: 1rem 5rem 2rem 5rem;
  }
  ${mqMax[4]} {
    padding: 1rem 3rem 2rem 3rem;
    margin-top: 2rem;
  }
`;

const CarouselWrapper = styled.div`
  padding: 4rem 0 0 0;
  ${mqMax[4]} {
    padding: 1rem;
  }
  .slick-slide:first-of-type {
    width: 10rem !important;
    ${mqMax[5]} {
      width: 4rem !important;
    }
    ${mqMax[4]} {
      width: 1rem !important;
    }
  }
`;
export const FirstSlideStylesContainer = styled.div`
  .slick-slide:first-of-type {
    width: 10rem !important;
  }
  ${mqMax[4]} {
    width: 1rem !important;
  }
`;
export const FirstEmptySlide = styled.div``;

const CollectionLink = styled.div<CollectionLinkProps>``;

const ImageTitleWrapper = styled.div<ImageTitleWrapperProps>`
  width: 100%;
  max-width: 900px;
  padding: 1rem;
  margin: ${(props) => (props.align === 'center' ? '1rem auto' : '1rem 0')};
`;

const LinkWrapper = styled.div<LinkWrapperProps>`
  text-align: ${({ align }) => (align === 'left' ? 'left' : 'center')};
`;

const StyledNextLink = styled(NextLink)<CollectionLinkProps>`
  font-size: 18px;
  display: inline-block;
  margin: 2rem 1rem;
  font-family: 'SunsetGothicProItalic';

  color: ${({ color, theme }) => color ?? theme.palette.primary.main};
`;

const ContentWrapper = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export {
  Wrapper,
  MediaWrapper,
  DefaultTitle,
  TextWrapper,
  CarouselWrapper,
  CollectionLink,
  ImageTitleWrapper,
  LinkWrapper,
  StyledNextLink,
  ContentWrapper,
};
