import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { FunctionComponent, useRef, useState } from 'react';
import { useInterval } from 'react-use';

import DrakenFilmMask from '@/assets/icons/DF_block-mask.svg';
import { Typography } from '@/components/Typography';
import { mqMax } from '@/styles/breakpoints';

import { DrakenFilmHero } from '../../payload-types';
import { ArrowIcon } from '../components/ArrowIcon';
import { MediaComponent } from '../components/Media';
import { useSubscriptionData } from '@/context/subscription/subscription.provider';
import { useAccountState } from '@/context/accountContext';
import { Button } from '@/components/Button/Button';
import { scrollToNext } from '@/utils/scrollToNext';

const OuterWrapper = styled.div`
`;

const MobileJoinButton = styled.div`
  display: none;
  ${mqMax[3]} {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 0rem;
  margin-bottom: 8rem;
  ${mqMax[3]} {
    flex-direction: column-reverse;
    margin-top: 6rem;
    margin-bottom: 4rem;
  }
  ${mqMax[4]} {
    flex-direction: column;
    padding-bottom: 12rem;
    margin-bottom: 0;
  }
`;

const ColumnWrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width ?? '50%'};

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${mqMax[3]} {
    width: 100%;

    max-height: 100%;
    align-items: center;
  }

  ${mqMax[4]} {
    padding: 0;
  }
    
`;

const MovieLogoWrapper = styled.div`
  width: 100%;
  padding-left: 1vw;
  padding-top: 1.5vw;
  ${mqMax[3]} {
    width: 60%;
    align-self: flex-end;
    padding: 1.4rem;
  }
  ${mqMax[4]} {
    width: 100%;
    padding: 0;
  }
`;

const SubTitleWrapper = styled.div`
  width: 90%;
  padding-top: 2rem;
  ${mqMax[3]} {
    width: 60%;
    align-self: flex-start;
    padding-left: 0.8vw;
  }
  ${mqMax[4]} {
    width: 100%;
  }
`;

const TextContentWrapper = styled.div`
  margin-left: 10%;
  width: 75%;
  max-width: 600px;
  margin-top: -5%;

  ${mqMax[3]} {
    width: auto;
    max-width: initial;
    margin-left: 2rem;
    margin-top: 0;
    padding: 2rem;
  }

  ${mqMax[4]} {
    margin-left: 0;
    padding: 3rem 1rem;
  }
`;

const MediaWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
`;

const MaskOverlay = styled.div<{ maskSrc?: string }>`
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  object-fit: cover;
  background: url('${(props) => props.maskSrc}') center/cover no-repeat;
  clip-path: url(#mask);
  z-index: 3;
`;

const MaskBG = styled.div<{ color?: string }>`
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background-color: ${(props) => props.color};
  z-index: 1;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: -1.5vw;
  transform: translate(-50%, -50%);

  ${mqMax[3]} {
    left: 3rem;
    bottom: 3rem;
    transform: none;
  }

  ${mqMax[4]} {
    left: 50%;
    bottom: -1%;
    transform: translate(-50%, -50%);
  }
`;

export const DrakenFilmHeroBlock: FunctionComponent<DrakenFilmHero> = (props) => {
  const [videoPlacement, setVideoPlacement] = useState(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const isTablet = useMediaQuery(mqMax[3]);
  const isMobile = useMediaQuery(mqMax[4]);

  const handleChangeVideoPlacement = () => {
    if (videoPlacement < 2) {
      setVideoPlacement(videoPlacement + 1);
    } else {
      setVideoPlacement(0);
    }
    if (videoRef.current) {
      videoRef.current.play();
    }
  };
  const theme = useTheme();

  useInterval(() => {
    handleChangeVideoPlacement();
  }, 5000);

  const { isActiveSubscriber } = useSubscriptionData();
  const { auth } = useAccountState();

  return (
    <OuterWrapper className='draken-film-hero-block'>
      <Wrapper key={props.id}>
        <ColumnWrapper width="40%">
          <MovieLogoWrapper>
            <Grid container>
              <Grid item xs={12}>
                <MediaWrapper>
                  <Box
                    height="100%"
                    width="100%"
                    style={{
                      aspectRatio: '660/320',
                      overflow: 'hidden',
                      position: 'relative'
                    }}
                  >
                    <MediaComponent source={props.media} style={{
                      display: videoPlacement === 0 ? 'block' : 'none',
                      position: 'absolute',
                      zIndex: 2,
                    }} />
                    <MaskOverlay maskSrc={DrakenFilmMask.src} />
                    <MaskBG color="#EC6446" />
                  </Box>
                </MediaWrapper>
              </Grid>
              <Grid item xs={12}>
                <MediaWrapper>
                  <Box
                    height="100%"
                    width="100%"
                    style={{
                      aspectRatio: '660/320',
                    }}
                  >
                    <MediaComponent source={props.media} style={{
                      display: videoPlacement === 1 ? 'block' : 'none',
                      position: 'absolute',
                      zIndex: 2
                    }} />
                    <MaskOverlay maskSrc={DrakenFilmMask.src} />
                    <MaskBG color="#569979" />
                  </Box>
                </MediaWrapper>
              </Grid>
              <Grid item xs={12}>
                <MediaWrapper>
                  <Box
                    height="100%"
                    width="100%"
                    style={{
                      aspectRatio: '660/320',
                    }}
                  >
                    <MediaComponent source={props.media} style={{
                      display: videoPlacement === 2 ? 'block' : 'none',
                      position: 'absolute',
                      zIndex: 2
                    }} />
                    <MaskOverlay maskSrc={DrakenFilmMask.src} />
                    <MaskBG color="#3B7AA6" />
                  </Box>
                </MediaWrapper>
              </Grid>
            </Grid>
          </MovieLogoWrapper>
        </ColumnWrapper>
        <ColumnWrapper width="60%">
          <TextContentWrapper>
            <Typography variant="headlineLargeDynamic" fontWeight="500" customColor={props.title.color as string}>
              {props.title.titleText}
            </Typography>
            <SubTitleWrapper>
              <Typography variant="bodyExtraLarge" fontWeight="200" customColor={props.subTitle.color as string}>
                {props.subTitle.subTitleText}
              </Typography>
            </SubTitleWrapper>
          </TextContentWrapper>
        </ColumnWrapper>

        <IconWrapper>
          <a onClick={scrollToNext('draken-film-hero-block')} style={{
            cursor: 'pointer'
          }}>
            <ArrowIcon direction="bottom" color={props.arrowColor?.color as string} />
          </a>
        </IconWrapper>
      </Wrapper>
      <MobileJoinButton>
        {(!isActiveSubscriber || !auth?.token) && (
          <Button href="/offers" size="small" color="white" openInNewTab={false}>
            {!auth?.token ? 'Gå med' : 'Börja titta'}
          </Button>
        )}
      </MobileJoinButton>
    </OuterWrapper>
  );
};
