import { FC } from 'react';

import MovieCollections from '@/containers/MovieCollections';
import { MoviesCollections } from '@/payload-types';

interface MoviesCollectionsBlockProps {
  block: MoviesCollections;
  clientIp?: string;
  hasSubscriptionForThisPage?: boolean;
}

const MoviesCollectionsBlock: FC<MoviesCollectionsBlockProps> = ({
  block,
  clientIp = '',
  hasSubscriptionForThisPage,
}) => {
  const { landingPageCollectionId, hideFromLoggedOutUser, previewCollectionId } = block;

  return (
    <MovieCollections
      hideFromLoggedOutUser={hideFromLoggedOutUser as boolean}
      previewCollectionId={previewCollectionId as string}
      landingPageCollectionId={landingPageCollectionId as string}
      clientIp={clientIp}
      hasSubscriptionForThisPage={hasSubscriptionForThisPage}
    />
  );
};

export default MoviesCollectionsBlock;
