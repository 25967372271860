export const scrollToNext = (containerClassName: string) => {
    return () => {
      const nextElement = document.querySelector(`.${containerClassName}`)?.nextElementSibling;
      if (nextElement) {
        nextElement.scrollIntoView({ behavior: 'smooth', block: 'start'});
        var headerOffset = 83;
        var elementPosition = nextElement.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.scrollY - headerOffset;
      
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    
      }
    }
  }

