import styled from '@emotion/styled';
import { useMediaQuery } from '@material-ui/core';
import { FunctionComponent,  useState } from 'react';
import Slider from 'react-slick';

import { Typography } from '@/components/Typography/Typography';
import { mqMax } from '@/styles/breakpoints';
import { isArticle } from '@/utils/typescript';

import { ArticlesCaruselItem } from './ArticlesCaruselItem';

import { ArticlesCarusel as ArticlesCaruselType, Media } from '../../payload-types';
import { MediaComponent } from '../components/Media';

const OuterWrapper = styled.div`
  position: relative;
  z-index: 10;
`;

const ArticlesCaruselWrapper = styled.div<{ dotsColor?: string }>`
  position: relative;
  background-color: #ebebeb;

  overflow: hidden;
  .slick-dots {
    bottom: 8rem;
    left: 5rem;
    text-align: left;
    li {
      margin: 0;
    }
    li button:before {
      border: 1px solid black;
      border-radius: 50%;
      opacity: 1;
      line-height: 10px;
      font-size: 10px;
      height: 10px;
      width: 10px;
      box-sizing: border-box;

      content: '';
    }

    li.slick-active button:before {
      content: '';
      background-color: ${({ theme }) => theme.palette.background.default};
      border: none;
    }
  }
  ${mqMax[5]} {
    .slick-dots {
      bottom: 4rem;
      left: 4rem;
    }
  }

  ${mqMax[4]} {
    padding-bottom: 2rem;
    .slick-dots {
      bottom: 0;
      left: 0%;
      text-align: center;      
    }
  }
`;

const SecondNavWrapper = styled.div`
  background-color: #c4c2c2;
  width: 100%;
  padding: 2rem;
  .slick-current {
    opacity: 0.5;
  }

  ${mqMax[3]} {
    padding: 1rem;
  }
`;

const DiagonalTextRight = styled.div`
  position: absolute;
  right: 5rem;
  top: 6rem;
  transform: rotate(90deg) translateX(100%);
  transform-origin: top right;
  text-transform: uppercase;

  ${mqMax[4]} {
    display: none;
  }

  ${mqMax[5]} {
    right: 4rem;
    top: 4rem;
  }

`;

const SecondNavItem = styled.div`
  height: 250px;
  min-width: 200px;
  padding: 2rem;
  cursor: pointer;

  ${mqMax[4]} {
    height: 200px;
  }
`;

const ArticlesCaruselBlock: FunctionComponent<ArticlesCaruselType> = (props) => {
  const [nav1, setNav1] = useState<Slider | undefined>(undefined);
  const [nav2, setNav2] = useState<Slider | undefined>(undefined);

  const { articles } = props;

  const show2 = useMediaQuery(mqMax[3]);
  const show3 = useMediaQuery(mqMax[2]);
  return (
    <OuterWrapper key={props.id}>
      {articles && articles?.length > 1 ? (
        <>
          <ArticlesCaruselWrapper>
            <DiagonalTextRight>
              <Typography variant="titleSmall" fontStyle="italic" customColor={props.diagonalTextColor as string}>
                {props.diagonalText}
              </Typography>
            </DiagonalTextRight>
            <div>
              <Slider
                infinite={true}
                dots={true}
                className="custom-slider"
                arrows={false}
                asNavFor={nav2}
                ref={(slider1) => { if (slider1 !== null) setNav1(slider1); }}
                autoplay={true}
                autoplaySpeed={10000}
                pauseOnHover={false}
                speed={1000}
              >
                {props.articles?.map(({ article }) => {
                  return (
                    isArticle(article) && (
                      <div key={article.id}>
                        <ArticlesCaruselItem
                          preambleColor={props.preambleColor as string}
                          headlineColor={props.headlineColor as string}
                          article={article}
                        />
                      </div>
                    )
                  );
                })}
              </Slider>
            </div>
          </ArticlesCaruselWrapper>
          <SecondNavWrapper>
            <Slider
              infinite={false}
              dots={false}
              arrows={false}
              slidesToShow={show2 ? 2 : show3 ? 3 : 4}
              focusOnSelect={true}
              swipe={true}
              asNavFor={nav1}
              ref={(slider2) => { if (slider2 !== null) setNav2(slider2); }}
            >
              {props.articles?.map(({ article }) => {
                return (
                  isArticle(article) && (
                    <div key={article.id}>
                      <SecondNavItem>
                        <MediaComponent source={article.articleImage as string | Media } style={{ aspectRatio: 16 / 9 }} />
                      </SecondNavItem>
                    </div>
                  )
                );
              })}
            </Slider>
          </SecondNavWrapper>
        </>
      ) : (
        articles?.[0] &&
        isArticle(articles[0].article) && (
          <ArticlesCaruselItem
            preambleColor={props.preambleColor as string}
            headlineColor={props.headlineColor as string}
            article={articles[0].article}
          />
        )
      )}
    </OuterWrapper>
  );
};

export { ArticlesCaruselBlock };
