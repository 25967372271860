import { css } from '@emotion/react';
import { Box, useMediaQuery } from '@material-ui/core';
import Link from 'next/link';
import { FC } from 'react';

import Carousel from '@/components/ThemedCarousel';
import { MovieCard } from '@/components/ThemedMovieCard';
import { LinkEdge } from '@/graphql/queries/__generated__/baseTypes.generated';
import { GetMagineIdBasicMovieQuery } from '@/graphql/queries/__generated__/movie.generated';
import { ViewableEdge } from '@/models/ICollection.interface';
import { CollectionsStylesConfig } from '@/payload-types';
import { RenderBlocks } from '@/payload/RenderBlocks';
import { ArrowIcon } from '@/payload/components/ArrowIcon';
import { MediaComponent } from '@/payload/components/Media';
import { mqMax } from '@/styles/breakpoints';
import { movieClick } from '@/utils/analytics';
import getMeta from '@/utils/getMeta';

import {
  CarouselWrapper,
  ContentWrapper,
  FirstEmptySlide,
  ImageTitleWrapper,
  MediaWrapper,
  TextWrapper,
  Wrapper,
} from './ThemedCollection.styles';

import LinkButton from '../Button/LinkButton';
import { Typography } from '../Typography';

import ThickIcon from '@/assets/icons/arrows/DF-arrow-text_16px.svg';

interface CollectionProps {
  magineId?: string;
  magineImage?: string | null;
  magineTitle?: string | null;
  magineBackground?: string;
  magineDescription?: string | null;
  linksEdges?: Array<Omit<LinkEdge, 'cursor'> | null> | null;
  viewableEdges?: Array<ViewableEdge | null> | null;
  recommendations?: Array<GetMagineIdBasicMovieQuery['viewer']['viewable'] & { __typename: 'Movie' }>;
  stylesConfig?: CollectionsStylesConfig;
  subTitleColor?: string;
  textColor?: string;
}
export const ThemedCollection: FC<CollectionProps> = ({
  textColor = '#FFFFFF',
  magineId,
  linksEdges,
  viewableEdges,
  stylesConfig,
  magineImage,
  magineTitle,
  magineDescription,
}) => {
  const lt1024 = useMediaQuery(mqMax[3]);
  const lt800 = useMediaQuery(mqMax[4]);

  const predictSlidesToShow = (orientation?: 'horizontal' | 'vertical') => {
    return orientation === 'horizontal' ? (lt800 ? 1.5 : lt1024 ? 2.5 : 3.5) : lt800 ? 1.5 : lt1024 ? 2.5 : 4.5;
  };
  const title = stylesConfig?.title?.titleText ?? magineTitle;

  // const background = stylesConfig?.background?.backgroundColor ?? magineBackground;
  const description = stylesConfig?.description?.descriptionText ?? magineDescription;

  const blocksEnabledOnMainPage = stylesConfig?.carusel?.filter((carusel) => carusel.enabledOnMainPage);

  return viewableEdges?.length ?? linksEdges?.length ? (
    <>
      <Wrapper
        align={stylesConfig?.align && stylesConfig.align === 'center' ? 'center' : 'flex-start'}
        backgroundColor={stylesConfig?.background?.backgroundColor as string | undefined}
        backgroundImage={!!stylesConfig?.background?.backgroundImage}
      >
        {stylesConfig?.background?.backgroundImage ? (
          <MediaWrapper>
            <MediaComponent source={stylesConfig?.background.backgroundImage} style={{
              opacity: 0.8
            }} />
          </MediaWrapper>
        ) : magineImage ? (
          <MediaWrapper>
            <img src={magineImage} alt="" />
          </MediaWrapper>
        ) : null}
        <ContentWrapper>
          <TextWrapper>
            <Box>
              {stylesConfig?.preTitle?.preTitleText ? (
                <Box mb={4}>
                  <Typography
                    variant="titleMedium"
                    align={stylesConfig.align ? stylesConfig.align : 'left'}
                    customColor={stylesConfig.preTitle?.color ?? 'white'}
                    styles={css`
                      text-transform: uppercase;
                      letter-spacing: 1px;
                    `}
                  >
                    {stylesConfig?.preTitle.preTitleText}
                  </Typography>
                </Box>
              ) : null}
              {stylesConfig?.titleType === 'text' ? (
                <Link href={`/kollektion/${magineId}`}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={stylesConfig.align === 'left' ? 'flex-start' : 'center'}
                  >
                    <Typography
                      variant="headlineSmallMedium"
                      align={stylesConfig.align ? stylesConfig.align : 'left'}
                      customColor={stylesConfig.title?.color ?? 'white'}
                      fontStyle="italic"
                      fontWeight="bold"
                      styles={css`
                        word-wrap: normal;
                        hyphens: auto;
                      `}
                    >
                      {title}
                    </Typography>
                    {!stylesConfig.link?.linkText && (
                      <Box ml={lt800 ? 3 : lt1024 ? 4 : 8} mt={lt800 ? 2 : 4}>
                        <ArrowIcon
                          hoverColor={stylesConfig.hoverColor as string | undefined}
                          color={stylesConfig.title?.color ?? 'white'}
                          direction="right"
                          size={lt800 ? 30 : lt1024 ? 40 : 60}
                          arrowSource={lt800 ? ThickIcon.src : undefined}
                        />
                      </Box>
                    )}
                  </Box>
                </Link>
              ) : stylesConfig?.titleType === 'image' && stylesConfig.titleImage ? (
                <ImageTitleWrapper align={stylesConfig.align ?? 'center'}>
                  <MediaComponent source={stylesConfig.titleImage} />
                </ImageTitleWrapper>
              ) : title ? (
                <Typography variant="headlineMedium" align="left" fontStyle="italic" fontWeight="bold">
                  {title}
                </Typography>
              ) : null}
            </Box>
            {stylesConfig?.description?.descriptionText ? (
              <Box mt={lt800 ? 4 : 6} mb={2}
                ml={stylesConfig.align == 'center' ? 'auto' : undefined}
                mr={stylesConfig.align == 'center' ? 'auto' : undefined}
                style={{
                maxWidth: '700px'
              }}>
                <Typography
                  variant="bodyLarge"
                  align={stylesConfig.align ? stylesConfig.align : 'left'}
                  customColor={stylesConfig.title?.color ?? 'white'}
                  fontWeight='300'
                >
                  {description}
                </Typography>
              </Box>
            ) : null}
            {magineId && stylesConfig?.link?.linkText && (
              <LinkButton
                path={`/kollektion/${magineId}`}
                arrowPlacement="right"
                color={stylesConfig?.link?.color as string | undefined}
                align={stylesConfig?.align as "left" | "center" | undefined}
                fontWeight="400"
                style={{
                  paddingLeft: 0,
                  marginTop: '2rem',
                }}
              >
                {stylesConfig?.link?.linkText ?? 'Se lista'}
              </LinkButton>
            )}
          </TextWrapper>

          {linksEdges && !!linksEdges.length ? (
            <CarouselWrapper>
              <Carousel
                slidesToShow={predictSlidesToShow(stylesConfig?.orientation as "horizontal" | "vertical" | undefined)}
                size={linksEdges.length}
              >
                <FirstEmptySlide />
                {linksEdges.map((movie) => (
                  <Box pl={2} pr={2} key={movie?.node.magineId}>
                    <MovieCard
                      textColor={textColor}
                      title={movie?.node.title}
                      thumbnail={movie?.node.image}
                      onClick={() => movieClick(movie?.node, getMeta('pageType'), 'title')}
                      orientation={stylesConfig?.orientation as "horizontal" | "vertical" | undefined}
                      isCollectionLink={movie?.node.__typename === 'CollectionLink'}
                      magineId={movie?.node.magineId}
                    />
                  </Box>
                ))}
              </Carousel>
            </CarouselWrapper>
          ) : null}
          {viewableEdges && !!viewableEdges.length ? (
            <CarouselWrapper>
              <Carousel
                slidesToShow={predictSlidesToShow(stylesConfig?.orientation as "horizontal" | "vertical" | undefined)}
                size={viewableEdges.length}
                color={stylesConfig?.hoverColor as string | undefined}
              >
                <FirstEmptySlide />
                {viewableEdges.map((movie) => (
                  <Box pl={2} pr={2} key={movie?.node.magineId}>
                    <MovieCard
                      textColor={textColor}
                      customId={movie?.node.customId}
                      title={movie?.node.title}
                      thumbnail={movie?.node.image}
                      onClick={() => movieClick(movie?.node, getMeta('pageType'), '4')}
                      hoverColor={stylesConfig?.hoverColor as string | undefined}
                      orientation={stylesConfig?.orientation as "horizontal" | "vertical" | undefined}
                    />
                  </Box>
                ))}
              </Carousel>
            </CarouselWrapper>
          ) : null}
        </ContentWrapper>
      </Wrapper>
      {blocksEnabledOnMainPage && <RenderBlocks blocks={blocksEnabledOnMainPage} />}
    </>
  ) : null;
};
